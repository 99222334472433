import React from "react";
import {BrowserRouter, Route, Link, Routes} from 'react-router-dom';
import Logo from "../../assets/images/fabevy-logo-landscape.png";
import './Header.css'

const Header = () => {
    return(
        <>
            <header className="header-section">
                <div className="container">
                    <nav className="navbar navbar-expand-lg p-0">
                        <a className="navbar-brand logo" href="#"><img src={Logo} alt="logo"className="img-fluid" /></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="https://www.fabevy.com/" target="_blank">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.fabevy.com/about.html" target="_blank">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.fabevy.com/job-guarantee-programs.html" target="_blank">Job Guarantee Programs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://www.fabevy.com/contact.html" target="_blank">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default Header;