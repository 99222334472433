import React, { useEffect, useState, useContext } from 'react';
import {BrowserRouter, Route, Link, Routes} from 'react-router-dom';
import { differenceInMonths } from 'date-fns';
import CertificateImg from '../../assets/images/certificate-img.jpg'
import CertificateIcon from '../../assets/images/certificate.png'
import './Certificate.css';
import { CreateContextApi } from "../../Context-Store/Context-Api";
import { saveAs } from 'file-saver';
import CertificateCreate from './CertificateCreate';
import { Tooltips } from '../Tooltips/Tooltips';
import moment from 'moment';


const Certificate = () => {

    const [getState, setDispatch] = useContext(CreateContextApi)
    const [certificateInpt, setCertificateInpt] = useState("");
    const [inputMsg, setInputMsg] = useState("");
    const [getCertificate, setCertificate] = useState(null);
    const [percentageResVal, setPercentageResVal] = useState("");
    const [mockStyle, setMockStyle] = useState();
    const [stDetails, setStDetails] = useState();
    const [startDuration, setStartDuration] = useState("");
    const [endDuration, setEndDuration] = useState("");
    const [projectVal, setProjectVal] = useState("");
    const [taskVal, setTaskVal] = useState("");

    
    
    const onInputHandler = (event) =>{
        setCertificateInpt(event.target.value);
        setInputMsg("");
        setCertificate();
    }
    const onVerify = (e) => {
      e.preventDefault();

      if(certificateInpt.trim() === ""){
        setInputMsg("Please enter certification No.");
        return;
      }else{
        setInputMsg("");
      }
       // Attendance Percentage
    const findCertificateById = getState.item.find((certId) => certId.certificateId === certificateInpt);
      if(findCertificateById){
        setCertificate(findCertificateById);
        setDispatch({type: "FETCH", payload:findCertificateById})

        // Attendance
        let attendancePercentage =  findCertificateById.attendance.split("/");
        let number1 = attendancePercentage[0];
        let number2 = attendancePercentage[1];
        setPercentageResVal(Math.floor((number1 / number2) * 100))
        setMockStyle(findCertificateById.mock_interview)
        setStartDuration(findCertificateById.completionStartDate); 
        setEndDuration(findCertificateById.completionEndDate);

        // Projects
        let projectsPercentage =  findCertificateById.projects.split("/");
        let project1 = projectsPercentage[0];
        let project2 = projectsPercentage[1];
        setProjectVal(Math.floor((project1 / project2) * 100))

        // Tasks
        let taskPercentage =  findCertificateById.tasks.split("/");
        let task1 = taskPercentage[0];
        let task2 = taskPercentage[1];
        setTaskVal(Math.floor((task1 / task2) * 100))



      }else{
        setInputMsg("Sorry, the Certificate ID does not exist. Please enter a valid Certificate ID.");
        setCertificate(null);
      }
    }

    // Download Certificate
    const downloadImg = () => {
        // saveAs(CertificateImg, 'Certificate.jpg')
    }   

    let mockStyles;
    let stDetailss;

    if(mockStyle === "Yet to Attend" || mockStyle === "Not Cleard"){
        mockStyles = {
            color: "orange",
        };  
        stDetailss = "Not complete mock";
    }
    if(mockStyle === "Not Eligible"){
        mockStyles = {
            color: "red",
        };

        stDetailss = "Need to improve Some Concepts";
    }
    if(mockStyle === "Cleard"){
        mockStyles = {
            color: "green",
        };     
        stDetailss = "Completed Mock";
    }

    // TotalDuration
    var mStartDate = moment(startDuration);
    var mTermDate = moment(endDuration);

    // Years
    var yearsDiff = mTermDate.diff(mStartDate, 'years');

    // Months
    var monthsDiff = mTermDate.diff(mStartDate, 'months', true);

    // setTotalDuration();
    const totalDuration = Math.floor(monthsDiff);
    // console.log(totalDuration)
    // TotalPercentage
    let projectRes;
    let projectStyles;
    if(projectVal <= 25){
        projectStyles = {
            color: "red",
        };
        projectRes = "Not completed";
    }
    if(projectVal >= 50){
        projectStyles = {
            color: "orange",
        }; 
        projectRes = "Need to complete some Projects"
    }
    if(projectVal >= 90){
        projectStyles = {
            color: "green",
        };  
        projectRes = "Completed the Projects";
    }
    
    // Tasks
    let taskRes;
    let taskStyles;
    if(taskVal <= 25){
        taskStyles = {
            color: "red",
        };
        taskRes = "Not completed";
    }
    if(taskVal >= 50){
        taskStyles = {
            color: "orange",
        }; 
        taskRes = "Need to complete some tasks";
    }
    if(taskVal >= 90){
        taskStyles = {
            color: "green",
        };  
        taskRes = "Completed the Tasks"
    }
    
    


    return(
        <>
            <div className="certificate-search">
                <div className="container">
                    <div className="certificate-dt-cont">
                        <h1>Verify Certificate <i className="fa fa-check"></i></h1>
                    </div>
                    <div className="candidate-dt">
                        <label className="input-name">Enter Certificate ID</label>
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-9 search-container">
                                <input type="text" className="form-control mb-3 search-input" placeholder="Ex: XQF-0S3V-4AFT-0YTJ" onChange={onInputHandler} value={certificateInpt}/>
                                <button className="reg-btn btn-bg min-wid m-0 search-btn" onClick={onVerify}><i className="fa fa-search" aria-hidden="true"></i></button>
                            </div>
                            {/* <div className="col-12 col-lg-3">
                                <button className="reg-btn btn-bg min-wid m-0" onClick={onVerify}><i className="fa fa-search" aria-hidden="true"></i> Verify</button>
                            </div> */}
                            <div className="col-12 msgLog"> 
                            {<p className="msg-inpt err-msg">{inputMsg}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {getCertificate && 
                <div className="certificate-section">
                    <label className="input-name">Certification Details</label>
                    {
                        getCertificate && <p>This Certificate verifies that <b>{getCertificate.firstname}{" "}{getCertificate.lastname}</b>,
                        Completed the <b style={{textTransform:"capitalize",whiteSpace:"nowrap"}}>{getCertificate.course}</b> Successfully.</p>
                    }
                        <div className="row mt-4">
                            <div className="col-12 col-lg-6 col-md-12 col-xl-5">
                                
                                    <div className="table-wrapper active-table">
                                        
                                        <div className='table-content'>
                                        
                                        <table className='table table-container certificate-table'>
                                            <tbody>
                                            {/* <tr>
                                                <th>Key Assessment Parameters</th>
                                                <th>Metrics</th>
                                            </tr> */}
                                            <tr>
                                                <th>Name</th>
                                                <td colSpan={3}>{getCertificate?.firstname}{" "}{getCertificate?.lastname}{getCertificate ? "":"-"}</td>
                                            </tr>  
                                            <tr>
                                                <th>Certification ID</th>
                                                <td colSpan={3}>{getCertificate?.certificateId}{getCertificate ? "":"-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Batch</th>
                                                <td colSpan={3}>{getCertificate?.batch}{getCertificate ? "":"-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Course Name</th>
                                                <td colSpan={3} style={{textTransform:"capitalize"}}>{getCertificate?.course}{getCertificate ? "":"-"}</td>
                                            </tr>
                                            <tr>
                                                <th rowSpan={2} style={{verticalAlign: "middle"}}>Duration</th>
                                                <td colSpan={2}>As per plan</td>
                                                <td colSpan={2} rowSpan={1}>{getCertificate?.duration ?  getCertificate.duration + " " : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} rowSpan={1}>Taken by Trainee
                                                </td>
                                                <td colSpan={2} rowSpan={1}>
                                                    {getCertificate ? totalDuration : ""} {" "} 
                                                    
                                                    {getCertificate ? "Months" : ""}{getCertificate ? "":"-"}
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <th>Completion Date</th>
                                                <td colSpan={3}>{getCertificate?.completionStartDate} {getCertificate ? "to" : ""} {getCertificate?.completionEndDate} {getCertificate ? "":"-"}</td>
                                            </tr> */}
                                            <tr>
                                                <th>Attendance</th>
                                                <td colSpan={3}>{getCertificate ? percentageResVal+"%" :""}{getCertificate ? "":"-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Tasks</th>
                                                <td colSpan={3}>
                                                    {getCertificate?.tasks}{getCertificate ? "":"-"}{" "}
                                                    {getCertificate && <Tooltips text={taskRes} style={taskStyles} project="Task Details"></Tooltips>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Projects</th>
                                                <td colSpan={3}>{getCertificate?.projects}{getCertificate ? "":"-"} {" "}
                                                {getCertificate && <Tooltips text={projectRes} style={projectStyles} project="Project Details"></Tooltips>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Mock Interview</th>
                                                <td colSpan={3}>
                                                    {getCertificate?.mock_interview}{getCertificate ? "":"-"} {" "}
                                                    {getCertificate && <Tooltips text={stDetailss} style={mockStyles} project="Mock Details"></Tooltips>}
                                                </td>
                                            </tr>
                                            {/* <tr>
                                                <td>Grade</td>
                                                <td>{getCertificate.grade}</td>
                                            </tr> */}
                                            <tr>
                                                <th>Github Link</th>
                                                <td colSpan={3}>{getCertificate ? <a href={`${getCertificate?.profileUrl}`} target="_blank">{getCertificate?.profileUrl}</a> : "-"}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        
                                    </div>
                                {/* <div className="candidate-dt">
                                    <label className="input-name">Credential URL</label>
                                    <div className="row">
                                        <div className="col-12 col-lg-9">
                                            <input type="text" className="form-control mb-3" placeholder="Enter the Credential URL"/>
                                        </div>
                                        <div className="col-12 col-lg-3">
                                            <button className="reg-btn btn-bg min-wid m-0"><i className="fa fa-link"></i> Copy URL</button>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <p>By using this above link you can update your certificate in linkedin. <a href="#">How?</a></p> */}
                            </div>
                            <div className="col-12 col-lg-6 col-md-12 col-xl-7">
                                <div className="certificate-img" id='divToPrint'>
                                {
                                    !getCertificate ? 
                                    (
                                        <div className='certificate-err-sec'>
                                            <img src={CertificateIcon} alt="CertificateImg" className="img-fluid" />
                                        </div>
                                    ) : (<CertificateCreate certificate={getCertificate}/>)}
                                    
                                </div>
                                {/* <div className="text-center">
                                    <button className="reg-btn btn-bg" onClick={downloadImg}><i className="fa fa-download"></i> Download Certificate</button>
                                </div> */}
                            </div>
                        </div>
                        <div className="faq">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-md-12">
                                    <div className='faq-list'>
                                        <div className="sec-title">Frequently Asked Question</div>
                                        <div className="accordion mb-4" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    How can I add this certificate to Linkedin?
                                                </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How can I add this certificate in Social media?
                                                </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-lg-4 col-md-12">
                                    <div className="share-certificate">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="sec-title">Share this Certificate</div>
                                                <ul className="d-flex">
                                                    <li><a href="#" className="share-icon icon-1"><i className="fa fa-linkedin"></i></a></li>
                                                    <li><a href="#" className="share-icon icon-2"><i className="fa fa-facebook"></i></a></li>
                                                    <li><a href="#" className="share-icon icon-3"><i className="fa fa-twitter"></i></a></li>
                                                    <li><a href="#" className="share-icon icon-4"><i className="fa fa-envelope-o"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            
                        </div>
                </div>
            }   
        </>
    )
}

export default Certificate;