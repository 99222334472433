import React, { useState } from 'react';
import './Tooltips.css'

export const Tooltips = ({text, children, style, project}) => {
    const [tooltip, setTooltip] = useState(false)
    return (
        <div className='tooltip-container'>
            <i className="fa fa-info-circle" aria-hidden="true" style={style}
            onMouseEnter={()=> setTooltip(true)}
            onMouseLeave={()=> setTooltip(false)}></i>
            {children}
            {tooltip && 
                <div className='tooltips'>
                    <h3>{project}</h3>
                    <p>{text}</p>
                </div>
            }
        </div>
    )
}
