import axios from "axios";
import { createContext, useEffect, useReducer, useState } from "react";
import PageLoader from "../Components/PageLoader/PageLoader";

const CreateContextApi = createContext({
    item:[],
    isLoad:false
})

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = "https://workplace.fabevy.com/";
// console.log(baseURL)
const ITEMS = {
    item:[],
    certificateData:null,
    isLoad:false
}
const createReducer = (state, {type, payload})=>{
    switch(type){
        case "LOAD":
            return {...state, isLoad: true};
        case "GETDATA":
            return {item: payload, isLoad: false};
        case "ERROR":
            return {...state, isLoad: false};
        case "FETCH":
            return {...state, certificateData: payload}
        default:
            return state;
    }
}

const CreateContextProvider = ({children})=>{
    const [getState, setDispatch] = useReducer(createReducer, ITEMS);
    const [isLoadData, setLoadData] = useState(false);
    const token = localStorage.getItem("fabtoken")
    const fetCertificate = async ()=>{
        setDispatch({type:"LOAD"});
        setLoadData(false)
        try{
            const response = await axios.get(`${baseURL}/course-certificate/certificate-list/index.php`,
            {
                headers:{
                    Authorization:token,
                }
            }
            )
            const data = response.data;
            setDispatch({type:"GETDATA", payload: data})
            setLoadData(true)
        }
        catch(err){
            setDispatch({type:"ERROR"})
            // setLoadData(true)
        }
    }

    useEffect(()=>{
        fetCertificate();
    }, [])

    return <CreateContextApi.Provider value={[getState, setDispatch]}>
        {isLoadData ? children : <PageLoader />}
    </CreateContextApi.Provider>
}

export {CreateContextApi, CreateContextProvider};