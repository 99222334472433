import React, { useRef } from 'react';
import "./CertificateCreate.css";
import logo from '../../assets/images/logo.png';
import img from '../../assets/images/img-1.png';
import sign from '../../assets/images/sign.png';
import qrimg from '../../assets/images/qrcode.png';
import { useEffect, useState, useContext } from 'react';
import { CreateContextApi } from "../../Context-Store/Context-Api";
import moment from 'moment/moment';
import CertificateIcon from '../../assets/images/certificate.png'
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import leftLogo from '../../assets/images/fabevy-logo-landscape.png'; // Import your left logo image
import rightLogo from '../../assets/images/img-1.png'; // Import your right logo image
import signature from '../../assets/images/signature.png'; // Import your signature image
import backgroundImage from '../../assets/images/bg-img.jpg'; // Import your background image
import qr from "../../assets/images/qrcode copy.png"
import BrittanySignature from "./BrittanySignature-normal"



function CertificateCreate({ certificate }) {
    const [getState, setDispatch] = useContext(CreateContextApi);
    const [certificateInfo, setCertificateInfo] = useState(getState.certificateData);
    // console.log(certificateInfo)
    // console.log(certificate);
    const [pdfSrc, setPdfSrc] = useState(""); // State to hold the data URI of the generated PDF

    const [pdfDownoad, setDownload] = useState("");
    useEffect(() => {
        generateCertificate(); // Generate certificate when the component mounts
    }, []); // Empty dependency array ensures the effect runs only once

    // const downloadImg = () => {
    //     const element = document.getElementById('certificate-container');

    //     // Calculate width and height based on the content size and desired margins
    //     const width = 316; // Specify the desired width of the PDF
    //     const height = 224; // Specify the desired height of the PDF

    //     html2canvas(element, { scale: 2 }).then(canvas => {
    //         const imgData = canvas.toDataURL('image/png');

    //         // Create a new jsPDF instance with specified dimensions
    //         const pdf = new jsPDF({
    //             orientation: 'landscape', // or 'portrait'
    //             unit: 'px', // units: mm, cm, in, px
    //             format: [width, height] // dimensions
    //         });

    //         // Add the captured image to the PDF
    //         pdf.addImage(imgData, 'PNG', 0, 0, width, height);

    //         // Save the PDF with a custom filename
    //         pdf.save(`${getState.certificateData.firstname} Certificate.pdf`);
    //     });
    // }

    const generateCertificate = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });

        // Add background image
        doc.addImage(backgroundImage, 'JPEG', 0, 0, 297, 210); // Adjust size and dimensions as needed

        // Add logo on the top right
        doc.addImage(leftLogo, 'PNG', 230, 25, 50, 10); // Adjust position and size as needed

        // Add logo on the top left
        doc.addImage(rightLogo, 'PNG', 18, 20, 50, 20); // Adjust position and size as needed

        // Participant's name and text content in the center


        //  doc.setFont('BrittanySignature');
        let convertSmallText = certificate.firstname.toLowerCase();
        let lastNameSmallText = certificate.lastname.toLowerCase();
        const participantName = convertSmallText[0].toUpperCase() + convertSmallText.slice(1) + "  " + lastNameSmallText[0].toUpperCase() + lastNameSmallText.slice(1);

        // const participantName = certificate.firstname + "  " + certificate.lastname;

        // Register custom font
        doc.addFont('BrittanySignature', 'normal');

        // Set font to use custom font
        doc.setFont('BrittanySignature');
        // doc.setFont(undefined, 'bold');
        doc.setFontSize(40);
        doc.text(participantName, 140, 65, { align: "center" });
        // Reset font to normal if needed
        doc.setFont("Helvetica", "normal");



        if (certificate.course == "BACKEND DEVELOPER-TRAINEE") {
            const course = certificate.course.toUpperCase();
            doc.setFont(undefined, 'bold');
            doc.setFontSize(18);
            doc.setTextColor("white");
            doc.setFillColor(160, 32, 240); // Yellow background color
            doc.rect(78, 82, doc.getTextWidth(course) + 22, 10, 'F');
            doc.text(course, 140, 89, { align: "center" });
            // Reset font style to normal and draw remaining text
            doc.setFont(undefined, 'normal');
        }
        else if (certificate.course == "ui-ux designer") {
            const course = certificate.course.toUpperCase() + "-TRAINEE";
            doc.setFont(undefined, 'bold');
            doc.setFontSize(18);
            doc.setTextColor("white");
            doc.setFillColor(160, 32, 240); // Yellow background color
            doc.rect(78, 82, doc.getTextWidth(course) + 40, 10, 'F');
            doc.text(course, 140, 89, { align: "center" });
            // Reset font style to normal and draw remaining text
            doc.setFont(undefined, 'normal');
        }
        else{
            const course = certificate.course.toUpperCase()+"-TRAINEE";
            doc.setFont(undefined, 'bold');
            doc.setFontSize(18);
            doc.setTextColor("white");
            doc.setFillColor(160, 32, 240); // Yellow background color
            doc.rect(78, 82, doc.getTextWidth(course) + 17, 10, 'F');
            doc.text(course, 140, 89, { align: "center" });
            // Reset font style to normal and draw remaining text
            doc.setFont(undefined, 'normal');
        }


        const content = "is here by certified for his / her participation & completion of Fabevy’s training";
        doc.setFontSize(14);
        doc.setTextColor("black");
        // doc.setFontType("bold");
        doc.text(content, 140, 101, { align: "center", maxWidth: 250, lineHeightFactor: 2 });

        // Front-end Development using ReactJS //  Backend Development using Python
        const bol = certificate.course;
        const normalText = "program titled";
        const boldText = ` ${bol} `;

        if (bol == "full stack developer") {
            let fsd = "Full-Stack Development using ReactJS & Python "
            doc.setFontSize(14);
            doc.setTextColor("black");

            // Draw normal text
            doc.text(normalText, 83, 108, { align: "center", maxWidth: 250 });

            // Set font style to bold and draw bold text
            doc.setFont(undefined, 'bold');
            doc.text(fsd, 125 + doc.getTextWidth(normalText), 108, { align: "center", maxWidth: 250 });

            // Reset font style to normal and draw remaining text
            doc.setFont(undefined, 'normal');
        }
        if (bol == "front-end developer") {
            let fed = "Front-End Development using ReactJS"
            doc.setFontSize(14);
            doc.setTextColor("black");

            // Draw normal text
            doc.text(normalText, 90, 108, { align: "center", maxWidth: 250 });

            // Set font style to bold and draw bold text
            doc.setFont(undefined, 'bold');
            doc.text(fed, 120 + doc.getTextWidth(normalText), 108, { align: "center", maxWidth: 250 });

            // Reset font style to normal and draw remaining text
            doc.setFont(undefined, 'normal');

        }
        if (bol == "BACKEND DEVELOPER-TRAINEE") {
            let bed = "Back-End Development using Python"
            doc.setFontSize(14);
            doc.setTextColor("black");

            // Draw normal text
            doc.text(normalText, 92, 108, { align: "center", maxWidth: 250 });

            // Set font style to bold and draw bold text
            doc.setFont(undefined, 'bold');
            doc.text(bed, 120 + doc.getTextWidth(normalText), 108, { align: "center", maxWidth: 250 });

            // Reset font style to normal and draw remaining text
            doc.setFont(undefined, 'normal');

        }
        if (bol == "ui-ux designer") {
            let fed = "UI/UX Designer using designing tools"
            doc.setFontSize(14);
            doc.setTextColor("black");

            // Draw normal text
            doc.text(normalText, 92, 108, { align: "center", maxWidth: 250 });

            // Set font style to bold and draw bold text
            doc.setFont(undefined, 'bold');
            doc.text(fed, 120 + doc.getTextWidth(normalText), 108, { align: "center", maxWidth: 250 });
            doc.setFont(undefined, 'normal');
            // doc.text("from", 180, 108, { align: "center", maxWidth: 250 });

            // Reset font style to normal and draw remaining text
            doc.setFont(undefined, 'normal');

        }


        const stDates = certificate.completionStartDate;
        let crtDat = stDates.split("-").pop();
        let conNumber = Number(crtDat);

        const endDates = certificate.completionEndDate;
        let crtDat1 = endDates.split("-").pop();
        let conNumber1 = Number(crtDat1);

        const bol1 = conNumber >= 10 ? moment(certificate.completionStartDate).format("Do MMM YY") : "0" + moment(certificate.completionStartDate).format("Do MMM YY");
        const bol2 = conNumber1 >= 10 ? moment(certificate.completionEndDate).format("Do MMM YY") : "0" + moment(certificate.completionEndDate).format("Do MMM YY");
        const normalText1 = "from";
        const normalText2 = "to";
        const boldText1 = ` ${bol1} `;
        const boldText2 = ` ${bol2}`

        doc.setFontSize(14);
        doc.setTextColor("black");

        // Draw normal text
        doc.text(normalText1, 103, 115, { align: "center", maxWidth: 250 });

        // Set font style to bold and draw bold text
        doc.setFont(undefined, 'bold');
        doc.text(boldText1, 113 + doc.getTextWidth(normalText1), 115, { align: "center", maxWidth: 250 });
        doc.setFont(undefined, 'normal');

        // Draw normal text
        doc.text(normalText2, 142, 115, { align: "center", maxWidth: 250 });
        // Reset font style to normal and draw remaining text
        // Set font style to bold and draw bold text
        doc.setFont(undefined, 'bold');
        doc.text(boldText2, 154 + doc.getTextWidth(normalText2), 115, { align: "center", maxWidth: 250 });
        doc.setFont(undefined, 'normal');

        doc.addImage(qr, 'PNG', 230, 161, 50, 30); // Adjust position and size as needed

        // Add signature in the center
        doc.addImage(signature, 'PNG', 120, 140, 65, 40); // Adjust position and size as needed

        // Left side text

        // Left side text
        const leftSideText1 = "Certificate ID :";
        doc.setFontSize(12);
        doc.setTextColor(160, 32, 240);
        doc.text(leftSideText1, 18, 170);

        const leftSideContent = certificate.certificateId;
        // Set font style to bold and draw bold text
        doc.setFont(undefined, 'bold');
        doc.setFontSize(10);
        doc.setTextColor(14, 14, 14);
        doc.text(leftSideContent, 18, 175);
        // Set font style to bold and draw bold text
        doc.setFont(undefined, 'normal');

        const leftSideText = "Certified on :";
        doc.setFontSize(12);
        doc.setTextColor(160, 32, 240);
        doc.text(leftSideText, 18, 185);

        // Set font style to bold and draw bold text
        doc.setFont(undefined, 'bold');
        const leftSideContent1 = moment(certificate.updatedAt).format("Do/MMM/YYYY")
        doc.setFontSize(10);
        doc.setTextColor(14, 14, 14);
        doc.text(leftSideContent1, 18, 190);
        // Set font style to bold and draw bold text
        doc.setFont(undefined, 'normal');



        // Generate data URI of the PDF
        const dataUri = doc.output('datauristring');

        // Create a Blob object from the data URI
        const blob = dataURItoBlob(dataUri);

        // Create a URL from the Blob object
        const url = URL.createObjectURL(blob);

        // Set the URL to the state
        setPdfSrc(url);
        //  Trigger download
        //  downloadPDF(blob, 'certificate.pdf');
        setDownload({ blob, filename: participantName })
    };

    // Function to convert data URI to Blob
    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const downloadPDF = () => {
        const { blob, filename } = pdfDownoad
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename + " " + "certificate";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <>
            <div className="certificate-create-section" id='certificate-container'>
                {
                    !certificateInfo ?
                        (
                            <div className='certificate-err-sec'>
                                <img src={CertificateIcon} alt="CertificateImg" className="img-fluid" />
                            </div>
                        ) : (<div>
                            <div className="header">
                                <div className="row align-items-center">
                                    <div className="col-6 col-lg-6">
                                        <div className="img-1">
                                            <img src={img} alt="img-1" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-6">
                                        <div className="img-2 text-end">
                                            <img src={logo} alt="logo" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-cont text-center">
                                <h1 className="name">{getState.certificateData.firstname}{" "}{getState.certificateData.lastname}</h1>
                                <h3 className="role">{getState.certificateData.course}</h3>
                                <p className="cont">is here by certified for his / her participation & completion of Fabevy’s <br /> training program titled <b className='coursename'>“{getState.certificateData.course}”</b> from <br />
                                    <b>{moment(getState.certificateData.completionStartDate).format("Do MMM YY")} to {moment(getState.certificateData.completionEndDate).format("Do MMM YY")}.</b></p>
                            </div>
                            <div className="footer-sec">
                                <div className="row align-items-end">
                                    <div className="col-3 col-lg-3">
                                        <div className="certificate-id">
                                            <h4>Certificate ID</h4>
                                            <p>{getState.certificateData.certificateId}</p>
                                        </div>
                                    </div>
                                    <div className="col-5 col-lg-5">
                                        <div className="sign-sec">
                                            <div className="sign-img">
                                                <img src={sign} alt="sign" className="img-fluid" />
                                            </div>
                                            <h2>Vel Murguan S</h2>
                                            <p>Founder & Managing Director, <br /> Fabevy Software Service</p>
                                        </div>
                                    </div>
                                    <div className="col-4 col-lg-4">
                                        <div className="qr-sec text-center">
                                            <h4>E-Verify</h4>
                                            <div className="qr-img">
                                                <img src={qrimg} alt="qrcode" className="img-fluid" />
                                            </div>
                                            <p><a href="http://www.certificate.fabevy.com/" target='_blank'>www.certificate.fabevy.com</a></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>)}

            </div>
            <div className="certificat-shows">
                <div className="" id='certificate-container' >
                {/* {
                     pdfSrc && (
                            <iframe
                                title="Generated Certificate"
                                src={pdfSrc}
                                style={{ width: "100%", height: "500px", border: "none" }}
                            />
                        )} */}
            </div>
                <div className="text-center pt-5">
                    <button className="reg-btn btn-bg" onClick={downloadPDF}><i className="fa fa-download"></i> Download Certificate</button>
                </div>
            </div>
            {/* <div className="text-center pt-5">
                <button className="reg-btn btn-bg" onClick={downloadImg}><i className="fa fa-download"></i> Download Certificate</button>
            </div> */}
        </>
    )
}

export default CertificateCreate