import "./PageLoader.css";

const PageLoader = () =>{
    
    return (
        <div className="page-loader d-flex align-items-center justify-content-center">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
    )
}

export default PageLoader;