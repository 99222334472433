import React from "react";
import {BrowserRouter, Route, Link, Routes} from 'react-router-dom';
import Logo from "../../assets/images/fabevy-logo-landscape.png";
import './Footer.css';

const Footer = () => {
    return(
        <>
            <footer className="footer-section">
                <div className="row">
                    {/* <div className="col-xl-2 col-md-6 col-lg-3">
                        <div className="ftr-logo mb-4">
                            <img src={Logo} alt="logo"className="img-fluid" />
                        </div>
                    </div> */}
                    <div className="col-xl-3 col-md-6 col-lg-3">
                        <div className="ftr-links">
                            <h3>Quick Links</h3>
                            <ul>
                                <li><a href="https://www.fabevy.com/" target="_blank">Home</a></li>
                                <li><a href="https://www.fabevy.com/about.html" target="_blank">About Us</a></li>
                                <li><a href="https://www.fabevy.com/job-guarantee-programs.html" target="_blank">Job Guarantee Programs</a></li>
                                <li><a href="https://www.fabevy.com/contact.html" target="_blank">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-lg-3">
                        <div className="ftr-links">
                            <h3>Our Programs</h3>
                            <ul>
                                <li><a href="https://www.fabevy.com/front-end-developer-courses.html">Front End Developer (React)</a></li>
                                {/* <li><a href="https://www.fabevy.com/angular-developer-courses.html">Front End Developer (Angular)</a></li> */}
                                <li><a href="https://www.fabevy.com/backend-developer-courses.html">Back End Developer (Python) </a></li>
                                <li><a href="https://www.fabevy.com/full-stack-developer-course.html">Full Stack Developer (Python)</a></li>
                                {/* <li><a href="https://www.fabevy.com/java-full-stack-developer-program.html">Full Stack Developer (Java)</a></li>
                                <li><a href="https://www.fabevy.com/ui-ux-designer-course.html">UI/UX Designer </a></li>
                                <li><a href="https://www.fabevy.com/digital-marketing.html">Digital Marketing</a></li>
                                <li><a href="https://www.fabevy.com/manual-testing-course.html">Manual Testing</a></li>
                                <li><a href="https://www.fabevy.com/api-testing-using-python.html">API Testing</a></li> */}
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-xl-2 col-md-6 col-lg-3">
                        <div className="ftr-links">
                            <h3>Chennai</h3>
                            <p>Navitha Apartments,
                            #44, 2nd St, MCN Nagar,
                            Thoraipakkam, OMR, Chennai,
                            Tamil Nadu - 600097</p>
                            <p><a href="tel:+91-9150372450" className="ftr-phone">+91 - 91503 72450</a></p>
                        </div>
                    </div> */}
                    <div className="col-xl-3 col-md-6 col-lg-3">
                        <div className="ftr-links">
                            <h3>Tenkasi</h3>
                            <p>5-156/8B Kamrajar Nagar, Kuthukalvalasai, Tenkasi 627811</p>
                            <p><a href="tel:+91-9150372409" className="ftr-phone">+91 - 91503 72409</a></p>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 col-lg-3">
                        <div className="ftr-links">
                            <h3>Pavoorchatram</h3>
                            <p>AV Complex, 10/210A, First Floor, Surandai Main Road, Pavoorchatram, Tenkasi, Tamil Nadu - 627808</p>
                            <p><a href="tel:+91-9150372409" className="ftr-phone">+91 - 91503 72409</a></p>
                        </div>
                    </div>
                </div>
                <div className="copyright text-center mt-5">
                    <p>© Copyright 2023 Fabevy Technologies. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    )
}

export default Footer;