import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import {CreateContextProvider} from "./Context-Store/Context-Api";
import './App.css';

function App() {
    return (
        <div className="App">
            <Header />
            <CreateContextProvider>
                <Main />
            </CreateContextProvider>
        </div>
    );
}

export default App;
