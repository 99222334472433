import React from "react";
import {BrowserRouter, Route, Link, Routes} from 'react-router-dom';
import Sidenav from "../Sidenav/Sidenav";
import Certificate from "../Certificate/Certificate";
import Footer from "../Footer/Footer";
import { useEffect, useState, useContext } from 'react';
import { CreateContextApi } from "../../Context-Store/Context-Api";
import PageLoader from "../PageLoader/PageLoader";

const Main = () => {
    const [getState, setDispatch] = useContext(CreateContextApi);
   

    return(
        
        <section className="main-cont-section">
            <div className="container">
                <div className="row">
                    {/* <div className="col-xl-1 p-0">
                        <Sidenav />
                    </div> */}
                    <div className="col-xl-12">
                        <Certificate />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
               <div className="row">
                    <div className="col-lg-12 p-0">
                    <Footer />
                    </div>
                </div> 
            </div>
        </section>
        
    )
}

export default Main;